var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-select":"","row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.nameTooltipIds.includes(item.id)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"truncate-tooltip",rawName:"v-truncate-tooltip",value:({
                                    id: item.id,
                                    type: 'nameTooltipIds',
                                }),expression:"{\n                                    id: item.id,\n                                    type: 'nameTooltipIds',\n                                }"}],staticClass:"text-truncate",staticStyle:{"max-width":"20vw"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.description",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.descriptionTooltipIds.includes(item.id)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"truncate-tooltip",rawName:"v-truncate-tooltip",value:({
                                    id: item.id,
                                    type: 'descriptionTooltipIds',
                                }),expression:"{\n                                    id: item.id,\n                                    type: 'descriptionTooltipIds',\n                                }"}],staticClass:"text-truncate",staticStyle:{"max-width":"30vw"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)},[_c('span',[_c('pre',[_vm._v(_vm._s(item.description))])])])]}},{key:"item.category.name",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.categoryTooltipIds.includes(item.id)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"truncate-tooltip",rawName:"v-truncate-tooltip",value:({
                                    id: item.id,
                                    type: 'categoryTooltipIds',
                                }),expression:"{\n                                    id: item.id,\n                                    type: 'categoryTooltipIds',\n                                }"}],staticClass:"text-truncate",staticStyle:{"max-width":"10vw"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.category.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.category.name))])])]}},{key:"item.isUpdate",fn:function(ref){
                                var item = ref.item;
return [(item.isUpdate)?_c('span',[_c('span',{staticClass:"material-icons"},[_vm._v("done")])]):_vm._e()]}},{key:"rowActions",fn:function(ref){
                                var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api,"strong":""},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api,"strong":""},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }